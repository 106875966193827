'use client'

import React from 'react'
import { Box, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import Slider from 'react-slick'
import Image1 from '../Assets/1.jpg'
import Image2 from '../Assets/2.jpg'
import Image3 from '../Assets/3.jpg'
import Image4 from '../Assets/4.jpg'
import Image5 from '../Assets/5.jpg'
import Image6 from '../Assets/6.jpg'
import Image7 from '../Assets/7.jpg'
import Image8 from '../Assets/8.jpg'
import Image9 from '../Assets/9.jpg'
import Image10 from '../Assets/10.jpg'
import Image11 from '../Assets/11.jpg'

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export default function Carousel() {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState<Slider | null>(null)

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = useBreakpointValue({ base: '90%', md: '50%' })
  const side = useBreakpointValue({ base: '30%', md: '10px' })

  // These are the images used in the slide
  const cards = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    Image10,
    Image11,
  ]

  return (
    <Box
      position={'relative'}
      height={'auto'}
      maxH={'90vh'}
      overflow={'hidden'}
      alignSelf={'center'}
      justifySelf={'center'}
      boxShadow={'sm'}
      backgroundColor={'#FEFEFE'}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}>
        <BiLeftArrowAlt />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        colorScheme="messenger"
        borderRadius="full"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}>
        <BiRightArrowAlt />
      </IconButton>
      {/* Slider */}
      <div style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          {cards.map((url, index) => (
            <div style={{
              backgroundColor: "red",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "auto",
            }} key={index}>
              <img
                key={index}
                src={url}
                alt={'slide'}
                style={{
                  width: "auto",
                  maxHeight: "90vh",
                  objectFit: "cover",
                  alignSelf: "center",
                  margin: "auto",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
    </Box>
  )
}