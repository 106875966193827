import {styled} from "@chakra-ui/react";
import Carousel from "../Components/Carousel.tsx";
import ImgDPE from "../Assets/dpe.jpg";

const HomeContent = styled("div", {
  baseStyle: {
    width: "100%",
    height: "100%",
  }
})

const Text = styled("div", {
  baseStyle: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  }
})

const TextTitle = styled("div", {
  baseStyle: {
    fontWeight: "semibold",
    color: "black",
    margin: "1rem",
    fontFamily: "DM Sans",
    fontSize: "1.5rem",
  }
})

const TextLine = styled("div", {
  baseStyle: {
    fontWeight: "regular",
    color: "black",
    margin: "1rem",
    fontFamily: "DM Sans",
    fontSize: "1.2rem",
  }
})

function Home() {

  return (
    <HomeContent>
      <Carousel/>
      <Text>
        <TextTitle>MAINNEVILLE, Proche de Gisors. Idéale pour famille et projet Gite.</TextTitle>
        <TextLine>Maison ancienne offrant de plain-pied de vie, un grand séjour de 41 m² avec cheminée ouverte, cuisine
          aménagée et équipée avec four à pain, salle de bains avec douche et baignoire, WC, une chambre avec sa
          mezzanine, à l’étage pièce palière desservant deux chambres. Une deuxième maison à aménager de 50 m² environ,
          bucher, atelier, chaufferie, cave avec petit caveau.</TextLine>
        <TextLine>Très belle vue sur la campagne, très beau terrain clos de haie sans vis à vis</TextLine>
        <TextLine>Terrain de 1771m²</TextLine>
        <TextLine>Pompe à chaleur neuve de cette année</TextLine>
        <TextLine>Ecole primaire sur place et maternelle à Hébécourt avec garderie</TextLine>
      </Text>

      <div style={{height: "1rem"}}/>
      <img
        src={ImgDPE}
        alt="DPE"
        style={{
          maxWidth: "600px",
          width: "100%",
          height: "auto",
        }}
      />

      <Text>
        <TextTitle>Contact</TextTitle>
        <TextLine>+33 6 34 98 93 84</TextLine>
        <TextLine>stephane.loeuillet@hotmail.com</TextLine>
      </Text>
    </HomeContent>
  )
}

export default Home